import axios from 'axios'
import Cookies from 'js-cookie'

const AuthCookies = 'auth'

// todo prod mode
// const BaseURL = 'http://api.bit.digitaltraverse.com/api/v1'
// dev mode with docker
const BaseURL = 'https://api.rxpro.digitaltraverse.com/api/v1'

const setAuthCookie = (value: string): void => {
  Cookies.set(AuthCookies, value)
}

const getAuthCookies = (): string => {
  return Cookies.get(AuthCookies) || 'no_token'
}

const clearAuthCookies = (): void => {
  Cookies.remove(AuthCookies)
}

const HttpRequest = axios.create({
  baseURL: BaseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export { BaseURL, HttpRequest, setAuthCookie, getAuthCookies, clearAuthCookies }
